/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

const $ = require('jquery');

//import { popover, tooltip, Toast, Carousel, Navbar, collapse, carousel } from 'bootstrap';



// start the Stimulus application
//import './bootstrap';

require('bootstrap/dist/js/bootstrap.bundle.min');
//require('bootstrap/js/dist/popover');
//require('bootstrap/js/dist/tooltip');



$(document).ready(function () {
    $('[data-bs-toggle="popover"]').popover();
    $('[data-bs-toggle="tooltip"]').tooltip();
});


document.addEventListener("DOMContentLoaded", function () {
    // make it as accordion for smaller screens
    if (window.innerWidth > 992) {

        document.querySelectorAll('.navbar .nav-item').forEach(function (everyitem) {

            everyitem.addEventListener('mouseover', function (e) {

                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');
                }

            });
            everyitem.addEventListener('mouseleave', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }


            })
        });

    }
    // end if innerWidth
});
// fermer les blocks pour les mobiles
if (window.matchMedia("(max-width: 767px)").matches) {
    var coll = document.getElementsByClassName("collapsible");
    var i;
    for (i = 0; i < coll.length; i++) {
        coll[i].classList.add("collapse");
        coll[i].classList.remove("show");
    }
}


var coll2 = document.getElementsByClassName("card-header");
var i;

for (i = 0; i < coll2.length; i++) {
    coll2[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
            this.querySelector(".fa-solid").classList.remove("fa-angle-up");
            this.querySelector(".fa-solid").classList.add("fa-angle-down");
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
            this.querySelector(".fa-solid").classList.remove("fa-angle-down");
            this.querySelector(".fa-solid").classList.add("fa-angle-up");
        }
    });
}


$(document).ready(function () {
    // Référence pour l'intervalle
    let scrollInterval;

    function startScroll() {
        scrollInterval = setInterval(function () {
            $("#barre_info ul").animate({ marginTop: -30 }, 500, function () {
                $(this).css({ marginTop: 0 }).find("li:last").after($(this).find("li:first"));
            });
        }, 3000);
    }

    function stopScroll() {
        clearInterval(scrollInterval);
    }

    // Initialisation du scroll uniquement s'il y a plus d'un élément
    if ($("#barre_info li").length > 1) {
        startScroll();

        // Arrêter le scroll au survol
        $("#barre_info").on("mouseenter", stopScroll);

        // Reprendre le scroll à la sortie de la souris
        $("#barre_info").on("mouseleave", startScroll);
    }
});







